<template>
<div>
    <div style="width: 100%;float: left;text-align:left; font-size:1.0125rem;margin-top: 30px">
        Your application has been approved. Now, you should provide more information and test cases.
    </div>
    <div style="width: 75%;float:left;margin-top: 20px">
        <Card style="width: 80%; float:left; margin-left:3% ;padding: 10px 5%; margin-top: 10px; margin-bottom: 50px">
            <div :model="join" style="text-align: left">
                <div class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500;display: inline; padding-right: 15px">
                        Project:
                    </div>
                    <span style="display: inline">{{projectName}}</span>
                </div>

                <div class="join_table_unit">
                    <div style="margin-bottom:10px;font-size: 1.025rem;float:left;width:100%;font-weight: 500;display: inline; padding-right: 15px">
                        Related module:
                    </div>
                    <Button v-for="items in this.join.moduleList" style="margin-right: 10px;">
                        {{items}}
                    </Button>
                </div>

                <div class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500;display: inline; padding-right: 15px">
                        Related Code Percentage:
                    </div>
                    <span style="display: inline">{{join.relatedPercentage}}%</span>
                </div>

                <div class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500;display: inline; padding-right: 15px">
                        Estimated Code Percentage for Permission:
                    </div>
                    <span>{{join.estimatedPercentage}} %</span>
                </div>

                <div class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500;display: inline; padding-right: 15px">
                        Type:
                    </div>
                    <p style="font-size: 1rem;font-weight: 400;display: inline;">{{join.type}}</p>

                </div>

                <div v-if="join.type === 'add new feature'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Function summary:
                    </div>
                    <p >{{join.functionSummary}}</p>
                </div>
                <div v-if="join.type === 'add new feature'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Description (user view):
                    </div>
                    <p style="font-size: 14px;color:darkgray">Please provide a concrete description from the
                        perspective of users.</p>
                    <p style="font-size: 14px;color:darkgray">You should give some examples to illustrate this new
                        feature.</p>

                    <p>{{join.userViewSescription}}</p>

                </div>
                <div v-if="join.type === 'add new feature'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Feature Technical Plan:
                    </div>
                    <p style="font-size: 14px;color:darkgray">If you can't give technical plan, <a
                            :href="'/#/furtherInfo?id='+projectId">you can ask for more detail</a>.</p>

                    <p>{{join.technicalPlan}}</p>
                </div>
                <!-- <div class="join_table_unit">
                     <Radio v-model="read_tag">Radio</Radio>
                 </div>-->
                <div v-if="join.type === 'fix bug'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Bug description:
                    </div>
                    <p style="font-size: 14px;color:darkgray">You can gives a brief overview and don't leave out
                        facts.</p>

                    <p>{{join.bugDescription}}</p>
                </div>

                <div v-if="join.type === 'fix bug'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Environment version:
                    </div>
                    <p style="font-size: 14px;color:darkgray">Environment description / version information</p>
                    <p>{{join.environmentVersion}}</p>
                </div>

                <div v-if="join.type === 'fix bug'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Bug route case:
                    </div>
                    <p style="font-size: 14px;color:darkgray">One of the very best ways you can report a bug is by
                        showing it to us! Show us the same thing again and demonstrate the thing that goes wrong</p>
                    <p>{{join.bugRouteCase}}</p>
                </div>

                <div v-if="join.type === 'fix bug'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Expected results:
                    </div>
                    <p style="font-size: 14px;color:darkgray"></p>
                    <p>{{join.expectedResults}}</p>
                </div>


                <div v-if="join.type === 'fix bug'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Screenshot:
                    </div>
                    <div v-for="items in uploadList"
                         style="width:100px;height:100px;float: left;margin-right: 15px;border: 1px solid darkgray;">
                        <!--<img :src="items.src" />-->
                        <img src="../assets/func.jpg" height="98" width="98"/>
                        <!--<div class="demo-upload-list-cover">
                            <Icon type="ios-eye-outline" @click.native="handleView(items.name)"></Icon>
                            <Icon type="ios-trash-outline" @click.native="handleRemove(items)"></Icon>
                        </div>-->
                        <div style="text-align:center;width: 100%;overflow: hidden;text-overflow:ellipsis;
white-space:nowrap; font-weight: 500;font-size: 0.83rem;">
                            {{items.name}}
                        </div>
                    </div>
                </div>


                <div v-if="join.type === 'fix bug'" class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Fix plan:
                    </div>
                    <p style="font-size: 14px;color:darkgray">If you don't have enough information to give fix plan,
                        you can apply for <a :href="'/#/furtherInfo?id='+projectId">more detail</a>.</p>
                    <p>{{join.fixPlan}}</p>
                </div>


                <div class="join_table_unit">
                    <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                        Test case description:
                    </div>
                    <p style="font-size: 14px;color:darkgray">Please provide a formal description about changes
                        after your contribution !</p>
                    <p>{{join.testCase}}</p>
                </div>
            </div>

        </Card>

    </div>
    <div style="width: 20%;float:left;text-align:left;margin-top: 20px;">
        <span style="color:darkgray;font-size: 0.85rem">
            Following permissions will be open.
        </span>
        <div  style="width: 100%;float: left;">
            <Tree :data="fileTree" expand="true" :load-data="loadTreeData"></Tree>
        </div>
    </div>
    <Divider></Divider>
    <div style="width: 80%; float:left; margin-left:3%;margin-bottom:30px;text-align: left">
        <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
            Test case description:
        </div>
        <p style="font-size: 14px;color:red">This part will decide if you can earn your contribution points after final commitment.</p>
        <Input type="textarea" style="width:90%;margin-top:0.5rem;float: left" v-model="further_test_cases"></Input>
    </div>

    <div style="width: 80%;float: left;margin-left: 3%;margin-bottom: 50px;text-align: left" class="table_unit">
        <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
            Test case:
        </div>
        <p style="font-size: 14px;color:red">You must give 5 test cases at least! If you can't give enough test cases, owners can reject your application with no reason.</p>
        <div style="margin-bottom:20px;margin-top: 20px;width: 60%;float: left">
            Input type:
            <Select v-if="count===1||edit_tag" v-model="InputType" style="float:right;width:200px;margin-right: 30%">
                <Option value="text" key="text">Text</Option>
                <Option value="file" key="file">File</Option>
            </Select>
            <p v-if="count===2&&!edit_tag" >{{OutputType}}</p>
        </div>
        <div style="margin-bottom: 20px;width: 60%;float: left">
            Output type:
            <Select v-if="count===1||edit_tag"  v-model="OutputType" style="float:right;width:200px;margin-right: 30%">
                <Option value="text" key="text">Text</Option>
                <Option value="file" key="file">File</Option>
            </Select>
            <p v-if="count===2&&!edit_tag" >{{OutputType}}</p>
        </div>
        <div class="table_unit" style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
            <div style="width:10%; float:left;display:inline">
                id
            </div>
            <div style="width:35%; float:left;display:inline">
                input
            </div>
            <div style="width:35%;margin-left:3%;float:left;display:inline">
                output
            </div>
        </div>
        <Form v-if="count===1||edit_tag" ref="formDynamic" :model="formDynamic" style="width: 100%;float:left">
            <FormItem
                    v-for="(item, index) in formDynamic.items"
                    v-if="item.status"
                    :key="index"
                    :prop="'items.' + index + '.value'"
            >
                <div class="table_unit" style="width:100%; float:left;">
                    <div  class="table_unit" style="width:10%; float:left;display:inline">
                       {{index+1}}
                    </div>
                    <div class="table_unit" style="width:35%; float:left;display:inline">
                        <div>
                            <Upload v-show="InputType === 'file'"
                                    :before-upload="(file)=>handleUpload(file,item.index-1)"
                                    action="/">
                                <Button style="text-align: left" type="primary" icon="ios-cloud-upload-outline">Select file     </Button>

                            </Upload>
                            <div v-show="InputType === 'file'" v-if="item.uploadList.length !== 0">
                                <div v-for="files in item.uploadList" style="width:100%; float:left;padding:0px 4%;margin-bottom:2px;background-color:#F5F5F5;">
                                    {{files.name}}
                                    <Button type="text" style="float: right;padding-top:2px; " @click="(file)=>handleTRemove1(item,item.index-1)"><Icon size="25" type="ios-close" /></Button>
                                </div>
                            </div>
                            <Input v-show="InputType === 'text'" type="textarea" v-model="item.value1" placeholder="Enter something..."></Input>
                        </div>
                    </div>
                    <div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
                        <div >
                            <Upload v-show="OutputType === 'file'"
                                    :before-upload="(file)=>handleUpload2(file,item.index-1)"
                                    action="/">
                                <Button style="text-align: left" type="primary" icon="ios-cloud-upload-outline">Select files     </Button>

                            </Upload>
                            <div v-show="OutputType === 'file'" v-if="item.uploadList2.length !== 0">
                                <div v-for="files in item.uploadList2" style="width:100%; float:left;padding:0px 4%;margin-bottom:2px;background-color:#F5F5F5;">
                                    {{files.name}}
                                    <Button type="text" style="float: right;padding-top:2px; " @click="(file)=>handleTRemove2(item,item.index-1)"><Icon size="25" type="ios-close" /></Button>
                                </div>
                            </div>
                            <Input v-show="OutputType === 'text'" type="textarea"  v-model="item.value2" placeholder="Enter something..."></Input>
                        </div>
                    </div>
                    <div class="table_unit" style="width:8%; margin-left:2%;float:left;display:inline">
                        <Button long v-if="index>4" @click="handleRemove2(index)">Delete</Button>
                    </div>
                    <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                    </hr>
                </div>
            </FormItem>
            <FormItem  v-if="count===1||edit_tag" >
                <div>

                        <Button type="dashed" long @click="handleAdd" icon="md-add" style="width: 30%;float: left;margin-left: 35%">Add item</Button>
                </div>
            </FormItem>
        </Form>

        <Form v-if="count===2&&!edit_tag" ref="formDynamic" :model="formDynamic" style="width: 100%;float:left">
            <FormItem
                    v-for="(item, index) in formDynamic.items"
                    v-if="item.status"
                    :key="index"
                    :prop="'items.' + index + '.value'"
            >
                <div class="table_unit" style="width:100%; float:left;">
                    <div  class="table_unit" style="width:10%; float:left;display:inline">
                        {{index+1}}
                    </div>
                    <div class="table_unit" style="width:35%; float:left;display:inline">
                        <div>
                            <div v-show="InputType === 'file'" v-if="item.uploadList.length !== 0">
                                <div v-for="files in item.uploadList" style="width:100%; float:left;padding:0px 4%;margin-bottom:2px;background-color:#F5F5F5;">
                                    {{files.name}}
                               </div>
                            </div>
                            <div v-show="InputType === 'text'">
                                {{item.value1}}
                            </div>
                        </div>
                    </div>
                    <div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
                        <div >
                            <div v-show="OutputType === 'file'" v-if="item.uploadList2.length !== 0">
                                <div v-for="files in item.uploadList2" style="width:100%; float:left;padding:0px 4%;margin-bottom:2px;background-color:#F5F5F5;">
                                    {{files.name}}
                                </div>
                            </div>
                            <div v-show="OutputType === 'text'" >
                                {{item.value2}}
                            </div>
                        </div>
                    </div>
                    <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                    </hr>
                </div>
            </FormItem>
            <FormItem  v-if="count===1||edit_tag" >
                <div>

                    <Button type="dashed" long @click="handleAdd" icon="md-add" style="width: 30%;float: left;margin-left: 35%">Add item</Button>
                </div>
            </FormItem>
        </Form>

        <div style="margin-top: 40px;width: 40%;margin-left: 30%">
        <Button v-if="count===1||edit_tag" type="primary" @click="submit2()" long>Submit</Button>
            <Modal v-model="modal2"
                   title="Submit your application."
                   @on-ok="real_submit"
                   @on-cancel="cancel"
                   width="600"
            >
                <p>You should check your test case description. It is important for you when computing your contribution. Make sure the number of your test cases is enough.</p>
                <p style="color: red">If you can't meet the requirements, your application can be rejected without giving any reason.</p>
            </Modal>
        </div>

        <div style="margin-top: 40px;width: 40%;margin-left: 30%">
            <Button v-if="count===2&&!edit_tag" type="primary" @click="Edit()" long>Edit</Button>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "createJoin2",
        data() {
            return {
                edit_tag:true, //判断是否修改？往往不修改
                count: 1, //往往第一次进入时是1
                modal1: false,
                projectId:'',
                projectName:'',
                contributionId: '',
                fileTree:[],
                branch: 'master',
                path: '/',
                join:{
                    bugDescription: '',
                    bugRouteCase: '',
                    environmentVersion: '',
                    estimatedPercentage: 0,
                    expectedResults: '',
                    fixPlan: '',
                    functionSummary: '',
                    moduleList: [],
                    //模块列表List《String》
                    id: '',
                    status:'',
                    projectId: '',
                    projectName:'',
                    relatedPercentage: 0, //相关比例
                    reply: "",//拒绝的理由
                    screenshotList: [],
                    technicalPlan: '',
                    testCase: "",//测试用例描述
                    type: 'fix bug', //fix function
                    userId: '', //用户ID
                    userViewSescription:"",  //截图地址列表
                    username: '', //用户名
                    //status: pass reject untreated
                },
                further_test_cases: '',
                InputType:'text',
                OutputType:'text',
                uploadList:[],
                modal2:false,
                index: 1,
                data1: [
                    {
                        title: 'parent 1',
                        expand: true,
                        children: [
                            {
                                title: 'parent 1-1',
                                expand: true,
                                children: [
                                    {
                                        title: 'leaf 1-1-1'
                                    },
                                    {
                                        title: 'leaf 1-1-2'
                                    }
                                ]
                            },
                            {
                                title: 'parent 1-2',
                                expand: true,
                                children: [
                                    {
                                        title: 'leaf 1-2-1'
                                    },
                                    {
                                        title: 'leaf 1-2-1'
                                    }
                                ]
                            }
                        ]
                    }
                ],

                columns1: [
                    {
                        title: 'File',
                        key: 'name'
                    },

                ],
                formDynamic: {
                    items: [
                        {
                            value1: '',
                            value2: '',
                            index: 1,
                            status: 1,
                            file: '',
                            uploadList:[],
                            uploadList2:[],
                            loadingStatus: false,
                        },
                        {
                            value1: '',
                            value2: '',
                            index: 2,
                            status: 2,
                            file: '',
                            uploadList:[],
                            uploadList2:[],
                            loadingStatus: false,
                        },
                        {
                            value1: '',
                            value2: '',
                            index: 3,
                            status: 3,
                            file: '',
                            uploadList:[],
                            uploadList2:[],
                            loadingStatus: false,
                        },
                        {
                            value1: '',
                            value2: '',
                            index: 4,
                            status: 4,
                            file: '',
                            uploadList:[],
                            uploadList2:[],
                            loadingStatus: false,
                        },
                        {
                            value1: '',
                            value2: '',
                            index: 5,
                            status: 5,
                            file: '',
                            uploadList:[],
                            uploadList2:[],
                            loadingStatus: false,
                        },

                    ]
                },


            }
        },
        methods:{
            solveFiles() {
                let Tself = this;
                let d1 = this.projectId;
                let d2 = this.path;
                let d3 = this.branch;
                this.path = '';
                this.$axios.get(
                    '/pit/getAllFile?projectId='
                    + d1 + '&relPath='
                    + d2 + '&branch='
                    + d3
                ).then(function (response) {
                    var dto = [];
                    var data23 = Array.from(response.data);
                    dto = data23.map(function (item) {
                            if (item.dir) {
                                return {
                                    loading: false,
                                    title: item.file,
                                    absolutePath: item.absolutePath,
                                    isDir: item.isDir,
                                    tag: item.tag,
                                    commitDate: item.commitDate,
                                    message: item.message,
                                    dir: item.dir,
                                    check: false,
                                    checkAll: false,
                                    reqPath: d2 + '/' + item.file, //用于请求的相对地址
                                    children: [], //用来添加文件夹下内容
                                };
                            }
                            return {
                                title: item.file,
                                absolutePath: item.absolutePath,
                                isDir: item.isDir,
                                tag: item.tag,
                                check: false,
                                checkAll: false,
                                commitDate: item.commitDate,
                                message: item.message,
                                dir: item.dir,
                                reqPath: d2 + '/' + item.file, //用于请求的相对地址
                            };
                        }
                    );
                    Tself.fileTree = dto;
                }).catch(function (error) {
                    console.log(error);
                });
            },
            loadTreeData(item, callback) {
                let Tself = this;
                let d1 = this.$route.query.id;
                let d2 = item.reqPath; //这里用的文件夹的相对地址
                let d3 = this.branch;
                this.$axios.get(
                    '/pit/getAllFile?projectId='
                    + d1 + '&relPath='
                    + d2 + '&branch='
                    + d3
                ).then(function (response) {
                    var dto = [];
                    var data23 = Array.from(response.data);
                    dto = data23.map(function (item) {
                            if (item.dir) {
                                return {
                                    loading: false,
                                    title: item.file,
                                    absolutePath: item.absolutePath,
                                    isDir: item.isDir,
                                    tag: item.tag,
                                    commitDate: item.commitDate,
                                    message: item.message,
                                    dir: item.dir,
                                    check: false,
                                    checkAll: false,
                                    reqPath: d2 + '/' + item.file, //用于请求的相对地址
                                    children: [], //用来添加文件夹下内容
                                };
                            }
                            return {
                                title: item.file,
                                absolutePath: item.absolutePath,
                                isDir: item.isDir,
                                tag: item.tag,
                                check: false,
                                checkAll: false,
                                commitDate: item.commitDate,
                                message: item.message,
                                dir: item.dir,
                                reqPath: d2 + '/' + item.file, //用于请求的相对地址
                            };
                        }
                    );
                    callback(dto);
                }).catch(function (error) {
                    console.log(error);
                });
            },
            handleSubmit (name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.$Message.success('Success!');
                    } else {
                        this.$Message.error('Fail!');
                    }
                })
            },
            handleReset (name) {
                this.$refs[name].resetFields();
            },
            handleAdd () {
                this.index++;
                this.formDynamic.items.push({
                    value1: '',
                    value2: '',
                    index: this.index,
                    status: 1,
                    file: null,
                    uploadList:[],
                    uploadList2:[],
                    loadingStatus: false,
                });
            },
            handleRemove2 (index) {
                this.formDynamic.items.pop();
            },
            initialData(){
                var Tself = this;
                this.projectId = this.$route.query.id;
                this.join.projectId = this.$route.query.id;
                this.userId = Tself.getCookie("user_id");
                this.join.userId = Tself.getCookie("user_id");
                this.join.username = Tself.getCookie("user_name");
                var userJoinVO = {
                    "projectId": this.projectId,
                    "userId": this.userId
                };
                this.$axios({
                        method: 'post',
                        url:"/join/findByUserIdAndProjectId",
                        data:userJoinVO,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                ).then((response) => {
                    var data = response.data.data.items;
                    Tself.projectName = data.projectName;
                    Tself.applicationNum = 1;
                    Tself.join.bugDescription = data.bugDescription;
                    Tself.join.bugRouteCase= data.bugRouteCase;
                    Tself.join.environmentVersion= data.environmentVersion;
                    Tself.join.estimatedPercentage= parseInt(data.estimatedPercentage);
                    Tself.join.expectedResults= data.expectedResults;
                    Tself.join.fixPlan= data.fixPlan;
                    Tself.join.functionSummary= data.functionSummary;
                    Tself.join.moduleList= data.moduleList;
                    Tself.join.relatedPercentage= parseInt(data.relatedPercentage); //相关比例
                    Tself.join.screenshotList= data.screenshotList;
                    Tself.join.technicalPlan= data.technicalPlan;
                    Tself.join.testCase= data.testCase;//测试用例描述
                    Tself.join.type= data.type; //fix function
                    Tself.join.userViewSescription= data.userViewSescription;//截图地址列表
                    Tself.join.status = data.status;
                    Tself.join.id = data.id;
                    Tself.count=data.count;
                    if(Tself.count === 2 ){
                        Tself.edit_tag = false;
                    }
                }).catch((error) => {
                    console.log("You should commit a join application.");
                    this.applicationNum = 0;
                })
            },
            submit(){
                this.modal1 =true;

            },
            cancel(){

            },
            ok(){},
            handleSuccess (res, file) {
                file.url = 'https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar';
                file.name = '7eb99afb9d5f317c912f08b5212fd69a';
            },
            handleFormatError (file) {
                this.$Notice.warning({
                    title: 'The file format is incorrect',
                    desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
                });
            },
            handleMaxSize (file) {
                this.$Notice.warning({
                    title: 'Exceeding file size limit',
                    desc: 'File  ' + file.name + ' is too large, no more than 2M.'
                });
            },
            submit2(){
                this.modal2 = true;
            },
            Edit(){
                this.edit_tag = true;
            },
            real_submit(){
                //do update
                this.join.testCase = this.further_test_cases;

                var lo =(this.join);
                // console.log("----lo")
                // console.log(lo)
                this.$axios.put(
                    "/join/updateJoin",
                    lo
                ).then((response) => {
                    var data = response.data.data.items;
                    this.count= data.count;
                    this.edit_tag=false;
                    this.$Message.success('OK!');
                }).catch((error) => {
                    this.$Message.error(error.message);
                });
            },
            handleUpload (file, index) {
                this.file = file;
                this.formDynamic.items[index].uploadList.push(file);
                return false;
            },
            handleUpload2 (file, index) {
                this.file = file;
                this.formDynamic.items[index].uploadList2.push(file);
                return false;
            },
            handleTRemove1(item, index) {
                item.uploadList.pop();

            },
            handleTRemove2 (item, index) {
                item.uploadList2.pop();
            },
            getCookie(cname){
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i=0; i<ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) { return c.substring(name.length,c.length); }
                }
                return "";
            },

        },
        mounted() {
            this.initialData();
            this.solveFiles();
        },
        updated() {

        }
    }
</script>

<style scoped>
    .join_main_container{
        width: 90%;
        margin-left: 5%;
        float: left;
        background-color: white;
    }

    .projectHeader{
        margin: 20px 0;
        width: 100%;
        height: 2rem;
        padding: 0 3.125rem;
        float: left;
    }
    .join_table_unit{
        padding-bottom: 40px;
    }

    .table_unit{
        /*border-style: solid;
        border-color: lightgray;
        border-width: 1px;*/
    }
</style>
